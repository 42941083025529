import axios from "axios";

const BACK_URL = process.env.REACT_APP_API_BASE_URL || "https://api.pixporhora.com.br";

const api = axios.create({
  baseURL: BACK_URL,
});

const apiLocal = axios.create({
  baseURL: "/api",
});

export { api, apiLocal };
