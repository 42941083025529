export default function Navbar() {
  return (
    <header className="flex items-center justify-center w-screen h-44 bg-[url('/public/navbar-bg.png')] bg-cover px-3 lg:px-36">
      <nav className="relative flex flex-col lg:flex-row w-full max-w-[1200px] h-fit items-center justify-between">
        <a href="/">
          <img
            src="/pixhora-loterj-logo.png"
            alt="Logo"
            className="w-[275px] h-[100px] lg:w-[550px] lg:h-[200px] lg:absolute lg:-top-[60px] lg:-left-20"
          />
        </a>

        <div className="flex flex-row w-fit items-center justify-center gap-2 md:gap-8 lg:pl-40 mt-2 lg:mt-0">
          <a
            href="/"
            className="text-white hover:text-gray-100 hover:font-semibold hover:text-[13px] font-medium text-[9px] lg:text-[12px] uppercase tracking-[2px] transition-all"
          >
            Início
          </a>
          <div className="w-2 h-2 bg-white/50 rounded-full" />
          <a
            className="text-white hover:text-gray-100 hover:font-semibold hover:text-[13px] font-medium text-[9px] lg:text-[12px] uppercase tracking-[2px] transition-all"
            href="https://api.whatsapp.com/send?phone=554731700629&text=Estou%20com%20d%C3%BAvidas%20com%20rela%C3%A7%C3%A3o%20ao%20PIX%20DA%20HORA"
            target="_blank"
          >
            Suporte
          </a>
          <div className="w-2 h-2 bg-white/50 rounded-full" />
          <a
            href="/termos"
            className="text-white hover:text-gray-100 hover:font-semibold hover:text-[13px] font-medium text-[9px] lg:text-[12px] uppercase tracking-[2px] transition-all"
          >
            Termos e Regulamento
          </a>
          <div className="w-2 h-2 bg-white/50 rounded-full" />
          <a
            href="/faq"
            className="text-white hover:text-gray-100 hover:font-semibold hover:text-[13px] font-medium text-[9px] lg:text-[12px] uppercase tracking-[2px] transition-all"
          >
            FAQ
          </a>
        </div>
      </nav>
    </header>
  );
}
