import ResultCard from "./ResultCard";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store";
import { Instagram } from "lucide-react";
import {
  CaretDown,
  CaretUp,
  Check,
  CurrencyCircleDollar,
} from "@phosphor-icons/react";
import * as Select from "@radix-ui/react-select";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { getResults } from "../lib/react-query/queryFunctions";
import { ResultSectionProps } from "@/@types/AppTypes";

let options = [
  {
    value: "day",
    description: "Últimos do dia",
  },
  {
    value: "week",
    description: "Últimos da semana",
  },
  {
    value: "month",
    description: "Últimos do mês",
  },
  {
    value: "year",
    description: "Últimos do ano",
  },
  {
    value: "hour",
    description: "Últimos da hora",
  },
];

export default function ResultsSection({
  results,
  priceOrder,
  value,
  setValue,
  setPriceOrder,
}: ResultSectionProps) {
  function handleOrderClick() {
    if (priceOrder === "asc") {
      setPriceOrder("desc");
    } else {
      setPriceOrder("asc");
    }
  }
  return (
    <section className="relative flex flex-col w-full h-fit items-center gap-1 mt-24 py-20 bg-section-radial">
      <img
        src={"/coins-pixhora.png"}
        alt="moedas"
        className="absolute w-[300px] h-[130px] -top-16"
      />

      <div className="flex flex-col lg:flex-row w-full h-fit items-center justify-between px-3 lg:px-8">
        <div className="flex flex-row items-center justify-center gap-3">
          <img
            src={"/pixhora-icon.png"}
            alt="icone pix hora"
            className="w-14 h-14"
          />
          <p className="text-3xl lg:text-4xl font-bold text-white">
            Sorteios Realizados
          </p>
        </div>

        <a
          href="https://www.instagram.com/pixporhora/"
          target="_blank"
          className="flex flex-row items-center gap-2 p-1 mt-5 lg:mt-0 rounded-full  bg-[#25D366]/70"
        >
          <div className="bg-[#25D366] rounded-full p-2">
            <Instagram className="text-white w-6 h-6" />
          </div>
          <p className="text-white font-inter font-light text-xs pr-1">
            Acompanhe os sorteios no Instagram.
          </p>
        </a>

        <div className="flex flex-row items-center justify-center gap-3 mt-3 lg:mt-0">
          <button
            className={`flex flex-row items-center justify-center px-4 py-2 gap-2 ${
              priceOrder === "asc" ? "bg-transparent" : "bg-[#C4FA00]"
            } border border-[#C4FA00] rounded-full`}
            onClick={handleOrderClick}
          >
            <CurrencyCircleDollar
              className={`w-5 h-5 ${
                priceOrder === "asc" ? "text-white" : "text-[#161E2C]"
              }`}
              weight="fill"
            />
            <span
              className={`${
                priceOrder === "asc" ? "text-white" : "text-[#161E2C]"
              } text-[15px] font-semibold tracking-tight`}
            >
              Maior valor
            </span>
          </button>

          <Select.Root value={value!} onValueChange={setValue}>
            <Select.Trigger
              className="inline-flex items-center justify-between rounded-full px-4 flex-grow h-10 gap-1 bg-transparent border border-[#C4FA00] hover:border-[#C4FA00]/80 focus:shadow-md"
              aria-label="Filtro Hora"
            >
              <Select.Value
                placeholder="Escolha abaixo"
                className="text-[16px] font-semibold tracking-tight"
              />
              <Select.Icon className="text-[#C4FA00]">
                <CaretDown />
              </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
              <Select.Content
                className="overflow-hidden bg-tertiary border-gray-100 rounded-md shadow-lg shadow-[#C4FA00] z-50"
                position="popper"
              >
                <Select.ScrollUpButton className="flex items-center justify-center h-6 bg-tertiary text-[#C4FA00] cursor-default">
                  <CaretUp />
                </Select.ScrollUpButton>
                <Select.Viewport className="p-1 z-50">
                  {options.map((option) => (
                    <Select.Item
                      key={option.value}
                      value={option.value!}
                      className="relative flex items-center text-xs text-white rounded h-6 pr-8 pl-6"
                    >
                      <Select.ItemText>
                        <span className="text-[15px] font-semibold tracking-tight text-[#C4FA00]">
                          {option.description}
                        </span>
                      </Select.ItemText>
                      <Select.ItemIndicator className="absolute inline-flex items-center justify-center left-0 h-6 text-[#C4FA00]">
                        <Check />
                      </Select.ItemIndicator>
                    </Select.Item>
                  ))}
                </Select.Viewport>
                <Select.ScrollDownButton className="">
                  <CaretDown />
                </Select.ScrollDownButton>
              </Select.Content>
            </Select.Portal>
          </Select.Root>
        </div>
      </div>

      <div className="flex flex-row py-2 gap-5 lg:gap-3 pl-5 lg:pl-14 mt-8 overflow-auto lg:scrollbar max-w-full max-h-[500px]">
        {results && results.length > 0 ? (
          results?.map((jackpot) => (
            <ResultCard
              key={jackpot.id}
              id={jackpot.id}
              date={jackpot.date}
              selected_color={jackpot.selected_color}
              price={jackpot.price}
              winners={jackpot.winners}
            />
          ))
        ) : (
          <p className="text-white text-sm">
            Ainda não existem sorteios realizados para esses parâmetros
          </p>
        )}
      </div>
    </section>
  );
}
