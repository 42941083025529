import React from "react";
import ReactDOM from "react-dom";
import "./globals.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { queryClient } from "./lib/react-query/queryClient";
import { store } from "./core/store";
import { RouterProvider } from "react-router-dom";
import { router } from "./core/Router";

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </QueryClientProvider>,
  document.getElementById("root")
);
