import { JackpotProps, ParticipantProps } from "./../../@types/AppTypes.d";
import { JackpotsState } from "@/@types/AppTypes";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: JackpotsState = {
  openJackpot: null,
  openJackpotParticipants: null,
};

export const jackpotsSlice = createSlice({
  name: "jackpots",
  initialState,
  reducers: {
    setOpenJackpot: (state, action: PayloadAction<JackpotProps[]>) => {
      state.openJackpot = action.payload;
    },
    setOpenJackpotParticipants: (
      state,
      action: PayloadAction<ParticipantProps[] | null>
    ) => {
      state.openJackpotParticipants = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOpenJackpot, setOpenJackpotParticipants } =
  jackpotsSlice.actions;

export default jackpotsSlice.reducer;
