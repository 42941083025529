import { DOTS, usePagination } from "../hooks/usePagination";
import { CaretLeft, CaretRight, DotsThree } from "@phosphor-icons/react";

export type PaginationControllerProps = {
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number) => void;
};

export default function PaginationController({
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  onPageChange,
}: PaginationControllerProps) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange!.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange![paginationRange!.length - 1];
  return (
    <div className="flex flex-row items-center justify-center w-full h-10 px-5 md:px-10 gap-5 md:gap-8">
      <button
        className="text-black hover:text-action3 disabled:text-black/60"
        disabled={currentPage === 1}
        onClick={onPrevious}
      >
        <CaretLeft className="w-4 h-4" weight="bold" />
      </button>

      <div className="flex flex-row w-fit gap-3">
        {paginationRange!.map((pageNumber: any) => {
          if (pageNumber === DOTS) {
            return (
              <div className="flex items-end">
                <DotsThree className="text-black/85 w-4 h-4" weight="light" />
              </div>
            );
          }

          return (
            <button
              className={`text-black text-sm ${
                currentPage === pageNumber
                  ? "bg-action rounded-full w-6 h-6"
                  : "hover:text-action3 hover:scale-110"
              }`}
              disabled={currentPage === pageNumber}
              onClick={() => onPageChange(Number(pageNumber))}
            >
              {pageNumber}
            </button>
          );
        })}
      </div>

      <button
        className="text-black hover:text-action3 disabled:text-white/60"
        disabled={currentPage === lastPage}
        onClick={onNext}
      >
        <CaretRight className="w-4 h-4" weight="bold" />
      </button>
    </div>
  );
}
