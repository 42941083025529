import { Plus, X } from "@phosphor-icons/react/dist/ssr";
import * as Accordion from "@radix-ui/react-accordion";

const faqList = [
  {
    question: "Quem Somos - Pix por hora ?",
    response:
      "No Pix por hora, combinamos a emoção do jogo com a eficiência e a rapidez do PIX, criando uma plataforma onde cada hora oferece uma nova chance de vitória. Somos movidos pela paixão de trazer a você não apenas um sorteio, mas uma experiência emocionante e gratificante. Aqui, a simplicidade encontra a emoção: apostas fáceis, sorteios a cada hora e prêmios entregues instantaneamente. Nossa missão é tornar cada momento do seu dia uma oportunidade de alegria e expectativa. Acreditamos no poder da sorte e no entusiasmo de uma chance bem jogada. Por isso, garantimos uma experiência segura, transparente e justa. Com Pix-por-hora, cada sorteio é uma porta aberta para a emoção, cada resultado um caminho para a surpresa.",
  },
  {
    question: "Como funciona o sorteio Pix por hora?",
    response:
      "É simples! A cada hora, realizamos um sorteio emocionante. Você aposta um valor e, se for sorteado, o prêmio é enviado instantaneamente via PIX – o mesmo método que você usou para fazer sua aposta.",
  },
  {
    question: "Quantas chances tenho de ganhar por dia?",
    response:
      "Você tem 24 chances por dia! Realizamos um sorteio a cada hora, então você pode apostar na hora que sentir que é a mais sortuda para você.",
  },
  {
    question: "Como posso participar dos sorteios?",
    response:
      "Participar é fácil! Basta clicar em 'participar', escolher o valor da sua aposta, informar seus dados para contato, concordar com os termos do sorteio, e efetuar o pagamento. Assim que seu pagamento for identificado, você estará concorrendo!",
  },
  {
    question: "Como e quando os ganhadores são anunciados?",
    response:
      "Anunciamos os ganhadores todos os dias às 18:00 no nosso canal do TELEGRAM. O prêmio é enviado automaticamente para a conta do vencedor.",
  },
  {
    question: "É seguro participar dos sorteios Pix por hora?",
    response:
      "Sim, garantimos total segurança e transparência em nossos sorteios. Cumprimos todas as normas legais e regulamentações para garantir que sua experiência seja não apenas emocionante, mas também segura.",
  },
  {
    question: "Existe um valor mínimo ou máximo de aposta?",
    response:
      "Sim, temos limites de aposta estabelecidos para garantir a justiça e o equilíbrio dos nossos sorteios. Você pode encontrar os detalhes sobre os limites de aposta em nossa seção de Termos e Condições.",
  },
  {
    question: "Posso participar de mais de um sorteio por dia?",
    response:
      "Claro! Você pode participar de quantos sorteios desejar, desde que respeite os limites de aposta para cada um.",
  },
  {
    question: "Como posso acompanhar os resultados dos sorteios?",
    response:
      "Você pode acompanhar os resultados na seção Últimos Sorteios no site, rolando a página para baixo.",
  },
  ,
  {
    question: "Onde posso encontrar os termos e condições dos sorteios?",
    response:
      "Nossos termos e condições estão disponíveis no site. Recomendamos que leia atentamente para entender todas as regras e regulamentos do nosso serviço.",
  },
];

export default function FAQAccordion() {
  return (
    <Accordion.Root
      className="w-full rounded-[32px] shadow-[0_2px_10px] shadow-black/5 border-2 border-white bg-white mb-4 lg:mb-8"
      type="single"
      collapsible
    >
      {faqList.map((item, index) => (
        <Accordion.Item
          value={`item-${index}`}
          className="p-5 focus-within:shadow-[#03A344] mt-px overflow-hidden first:mt-0 first:rounded-t-[32px] last:rounded-b-[32px] focus-within:relative focus-within:z-10 focus-within:shadow-[0_0_0_2px]"
          key={index}
        >
          <Accordion.Header className="flex ">
            <Accordion.Trigger className="font-bold text-[15px] lg:text-[25px] text-black shadow-transparent hover:bg-white group flex h-[80px] flex-1 cursor-default items-center justify-between bg-white px-5 leading-none shadow-[0_1px_0] outline-none transition-all">
              {item?.question}
              <div className="flex items-center justify-center p-2 bg-[#F3F5F6] rounded-full ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:hidden">
                <Plus
                  className="text-black font-bold w-[14px] h-[14px]"
                  aria-hidden
                  weight="bold"
                />
              </div>
              <div className="flex items-center justify-center p-2 bg-gradient-to-r from-[#03A344] to-[#E7D289] text-white rounded-full ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=closed]:hidden">
                <X
                  weight="bold"
                  className="text-white font-bold w-[14px] h-[14px]"
                  aria-hidden
                />
              </div>
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="text-black bg-white data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-[15px]">
            <p className="py-5">{item?.response}</p>
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
}
