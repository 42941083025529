import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import JackpotCard from "./JackpotCard";
import { JackpotProps, ParticipantProps } from "@/@types/AppTypes";
import JackpotDayCard from "./JackpotDayCard";

export type CardSliderProps = {
  jackpotArray: JackpotProps[];
  jackpotParticipants: ParticipantProps[] | null;
  setOpenMyTicketsModal: (data: boolean) => void;
  setOpenParticipantsModal: (data: boolean) => void;
};

export default function CardSlider({
  jackpotArray,
  jackpotParticipants,
  setOpenMyTicketsModal,
  setOpenParticipantsModal,
}: CardSliderProps) {
  return (
    <div className="flex w-full lg:flex-1 items-center justify-start mt-2">
      <Swiper
        breakpoints={{
          350: {
            slidesPerView: 1.6,
          },
          1024: {
            slidesPerView: 2.2,
            spaceBetween: 0,
          },
          1280: {
            slidesPerView: 2.8,
            spaceBetween: 0,
          },
        }}
        freeMode={false}
        loop={true}
        modules={[FreeMode, Pagination]}
        className="flex justify-center max-w-full lg:max-w-[99%] bg-transparent"
        wrapperClass="flex items-center"
      >
        {jackpotArray.map((jackpot, index) => (
          <SwiperSlide
            key={index}
            className="bg-transparent shadow-none pl-3 py-5"
          >
            <JackpotDayCard
              jackpot={jackpot}
              key={index}
              setOpenMyTicketsModal={setOpenMyTicketsModal}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
