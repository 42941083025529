export default function CardSectionSkeleton() {
  return (
    <div className="relative flex flex-col w-full max-w-[360px] lg:max-w-[480px] items-center rounded-[40px] bg-white p-6 lg:p-10">
      <section className="bg-[#FAFAFA]  shadow rounded-md p-4 w-full h-fit  mx-auto">
        <div className="animate-pulse flex space-x-4 place-content-center">
          <div className="flex-1 space-y-6 py-1 flex flex-col items-center justify-center">
            <div className="h-2 w-[80%] bg-[#2B444C] rounded"></div>
            <div className="h-2 w-[50%] bg-[#2B444C] rounded my-2"></div>
            <div className="h-10 w-[45%] bg-[#2B444C] rounded-full"></div>
            <div className="h-14 w-24 bg-[#2B444C] rounded-lg border-b-2 border-b-black"></div>
            <div className="h-2 w-[90%] bg-[#2B444C] rounded"></div>
            <div className="h-2 w-[30%] bg-[#2B444C] rounded"></div>
          </div>
        </div>
      </section>

      <section className="bg-transparent  p-4 w-full h-fit md:mt-3 mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-3 py-1">
            <div className="h-2 bg-[#2B444C] rounded"></div>
            <div className="h-2 bg-[#2B444C] rounded"></div>
          </div>
        </div>
      </section>

      <section className="bg-transparent p-4 w-full h-fit md:mt-3 mx-auto">
        <div className="animate-pulse flex space-x-4 place-content-center">
          <div className="rounded-full bg-[#2B444C] h-12 w-[60%]"></div>
        </div>
      </section>

      <section className="bg-transparent  p-4 w-full h-fit md:mt-3 mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-3 py-1">
            <div className="h-2 bg-[#2B444C] rounded"></div>
            <div className="h-2 bg-[#2B444C] rounded"></div>
          </div>
        </div>
      </section>
    </div>
  );
}
