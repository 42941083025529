import React from "react";

const Timer = ({ value, name, hours = false, slider = false }) => (
  <div className={`flex flex-col items-center ${hours ? "px-4 py-3" : "p-1"}`}>
    <h1
      className={`text-[#4D4D4D] ${
        slider ? "text-[20px] leading-[24px]" : "text-[32px] leading-[35px]"
      }   font-normal`}
    >
      {value}
    </h1>
    <h2 className={`text-[#4D4D4D] capitalize tracking-wider text-[11px]`}>
      {name}
    </h2>
  </div>
);

export default Timer;
