import Timer from "../Timer";
import {
  ArrowRight,
  CalendarBlank,
  UsersFour,
  Timer as ClockTimer,
  Trophy,
  Users,
  Star,
} from "@phosphor-icons/react";
import {
  JackpotProps,
  ParticipantProps,
  UpperSectionProps,
} from "../../@types/AppTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../core/store";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/pt-br";
import { useEffect, useState } from "react";
import CardSectionSkeleton from "../CardSectionSkeleton";
import JackpotBarCountdown from "../JackpotBarCountdown";
import LastParticipantRowUpperSection from "../LastParticipantRowUpperSection";
import SimpleJackpotBarCountdown from "../SimpleJackpotBarCountdown";
import ParticipantsByIdModal from "../modals/ParticipantsByIdModal";

export function padWithZeros(number: number, minLength: number) {
  const numberString = number.toString();
  if (numberString.length >= minLength) return numberString;
  return "0".repeat(minLength - numberString.length) + numberString;
}

export type JackpotDayCardProps = {
  jackpot: JackpotProps;
  setOpenMyTicketsModal: (data: boolean) => void;
};

export default function JackpotDayCard({
  jackpot,
  setOpenMyTicketsModal,
}: JackpotDayCardProps) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(duration);
  const name = localStorage.getItem("name");
  const [openParticipantsByIdModal, setOpenParticipantsByIdModal] =
    useState(false);

  const [remainingTime, setRemainingTime] = useState({
    months: "00",
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const [countdown, setCountdown] = useState(100);

  useEffect(() => {
    const localEndTime = dayjs(jackpot?.end_datetime)
      .tz(dayjs.tz.guess())
      .locale("pt-br");
    const updateRemainingTime = () => {
      const now = dayjs().tz(dayjs.tz.guess());
      const duration = dayjs.duration(localEndTime.diff(now)).locale("pt-br");
      const progress = (duration.asMilliseconds() * 100) / 3600000;

      const minutes = padWithZeros(Math.max(0, duration.minutes()), 2);
      const seconds = padWithZeros(Math.max(0, duration.seconds()), 2);
      const hours = padWithZeros(Math.max(0, duration.hours()), 2);
      const days = padWithZeros(Math.max(0, duration.days()), 2);
      const months = padWithZeros(Math.max(0, duration.months()), 2);

      setRemainingTime({ months, days, hours, minutes, seconds });
      setCountdown(progress);
    };

    const timer = setInterval(updateRemainingTime, 1000);
    updateRemainingTime();

    return () => clearInterval(timer);
  }, [jackpot?.end_datetime]);

  return (
    <>
      <ParticipantsByIdModal
        isOpen={openParticipantsByIdModal}
        setIsOpen={setOpenParticipantsByIdModal}
        jackpot={jackpot}
      />
      {jackpot ? (
        <div className="relative flex flex-col w-full max-w-[190px]  items-center rounded-[12px] bg-white border-2 border-[#EE7219] shadow-lg">
          <div className="absolute -top-5 left-[50%] translate-x-[-50%] flex whitespace-nowrap items-center justify-center py-2 px-3 gap-2 rounded-full bg-[#EE7219]">
            <Star className="w-[18px] h-[18px] text-white" weight="fill" />
            <span className="text-[14px] font-medium text-white">
              {jackpot.type === "year"
                ? "PixANO"
                : jackpot.type === "month"
                ? "PixMÊS"
                : jackpot.type === "week"
                ? "PixSEMANA"
                : "PixDIA"}
            </span>
          </div>
          <div className="flex flex-col w-full max-w-[190px] pt-3 pb-1 px-1 items-center justify-center  gap-[1px] bg-white rounded-t-[12px] shadow-lg">
            <div className="flex flex-row w-fit items-center justify-center gap-1">
              {Number(remainingTime.months) > 0 ? (
                <>
                  <Timer value={remainingTime.months} name="Meses" slider />
                  <div className="flex flex-col">
                    <h1 className="text-[#4D4D4D] lg:text-2xl font-semibold">
                      :
                    </h1>
                    <br></br>
                  </div>
                  <Timer value={remainingTime.days} name="Dias" slider />
                </>
              ) : Number(remainingTime.days) > 0 ||
                Number(remainingTime.hours) > 0 ? (
                <>
                  <Timer value={remainingTime.days} name="Dias" slider />
                  <div className="flex flex-col">
                    <h1 className="text-[#4D4D4D] lg:text-2xl font-semibold">
                      :
                    </h1>
                    <br></br>
                  </div>
                  <Timer value={remainingTime.hours} name="Horas" slider />
                </>
              ) : (
                <>
                  <Timer value={remainingTime.minutes} name="Minutos" slider />
                  <div className="flex flex-col">
                    <h1 className="text-[#4D4D4D] lg:text-2xl font-semibold">
                      :
                    </h1>
                    <br></br>
                  </div>
                  <Timer value={remainingTime.seconds} name="Segundos" slider />
                </>
              )}
            </div>
            {Number(remainingTime.months) < 1 &&
              Number(remainingTime.days) < 1 &&
              Number(remainingTime.hours) < 1 && (
                <div className="flex flex-row w-full gap-2 items-center justify-center">
                  <ClockTimer
                    className="w-6 h-6 text-[#6B6B6B]"
                    weight="bold"
                  />
                  <SimpleJackpotBarCountdown
                    progress={countdown > 0 ? countdown : 0}
                  />
                </div>
              )}
          </div>
          <div className="flex flex-col items-center justify-center w-full bg-[#F2F2F2] rounded-b-[12px] py-[10px] px-3 gap-2">
            <div className="flex flex-column gap-2 items-center justify-center">
              <div className="flex flex-row gap-2 items-center justify-center px-3 py-[5px] rounded-full bg-[#6DC032] text-white">
                <Trophy className="w-5 h-5" weight="bold" />
                <span className="flex items-center gap-2 text-[15px] leading-[18px] font-medium">
                  {jackpot?.winners_amount}
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center justify-center px-3 py-[5px] rounded-full bg-[#6DC032] text-white">
                <Users className="w-5 h-5" weight="bold" />
                <span className="flex items-center gap-2 text-[15px] leading-[18px] font-medium">
                  {jackpot?.participantCount}
                </span>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center gap-[2px]">
              <p className="text-xl text-[#03A344]">R$</p>
              <p className="text-xl font-bold text-transparent bg-clip-text bg-[#03A344]">
                {new Intl.NumberFormat("pt-br", {
                  style: "currency",
                  currency: "BRL",
                  currencyDisplay: "code",
                })
                  .format(Number(jackpot ? jackpot?.total_sold! : 400))
                  .replace("BRL", "")
                  .trim()}
              </p>
            </div>
            <button
              className={`${
                name ? "flex" : "hidden"
              } flex-row w-fit items-center justify-center gap-1 px-4 py-2 rounded-full bg-[#E4E4E4] `}
              onClick={() => setOpenMyTicketsModal(true)}
            >
              <span className="text-[#383838] font-medium text-[10px] ">
                VOCÊ POSSUI {jackpot.totalTickets ? jackpot.totalTickets : 0}{" "}
                {jackpot.totalTickets > 1 ? "TICKETS" : "TICKET"}
              </span>
            </button>

            {!jackpot.hide_participants && jackpot.type === "hour" && (
              <div className="flex flex-col w-[105%] items-center justify-center">
                <button
                  className={`flex flex-row items-center justify-center px-4 py-1 gap-3 rounded-full bg-white border-2 border-white hover:border-[#5F5F5F]`}
                  onClick={() => {
                    setOpenParticipantsByIdModal(true);
                  }}
                >
                  <UsersFour
                    className="w-5 h-5  text-[#5F5F5F]"
                    weight="fill"
                  />
                  <span className="text-[#5F5F5F] font-medium text-[9px] lg:text-[11px]">
                    Últimos participantes
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <CardSectionSkeleton />
      )}
    </>
  );
}
