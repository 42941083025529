import Navbar from "../../components/Navbar";
import FAQAccordion from "../../partials/FAQAccordion";

export default function FAQ() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-start gap-4 lg:gap-20 overflow-x-clip scroll-smooth bg-[#E9F1EF]">
      <Navbar />

      <div className="flex flex-col w-full h-fit px-4 lg:px-32 gap-4 lg:gap-8">
        <div className="flex flex-col px-4">
          <span className="font-inter font-extrabold text-[22px] lg:text-[42px] lg:leading-[50px] uppercase">
            Perguntas Frequentes
          </span>
          <span className="font-medium text-[7px] lg:text-[15px] tracking-[2px] lg:leading-[50px] uppercase text-[#5F5F5F]">
            Veja abaixo AS DÚVIDAS MAIS FREQUENTES DOS NOSSOS USUÁRIOS
          </span>
        </div>

        <div className="flex flex-row w-full h-fit gap-8 scroll-smooth">
          <FAQAccordion />
        </div>
      </div>
    </main>
  );
}
