import Timer from "../../components/Timer";
import {
  ArrowRight,
  CalendarBlank,
  UsersFour,
  Timer as ClockTimer,
  Trophy,
  Seal,
  Star,
  Ticket,
  Users,
} from "@phosphor-icons/react";
import {
  JackpotProps,
  ParticipantProps,
  ProfileResponse,
  UpperSectionProps,
} from "../../@types/AppTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../core/store";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/pt-br";
import { useEffect, useState } from "react";
import CardSectionSkeleton from "../../components/CardSectionSkeleton";
import JackpotBarCountdown from "../../components/JackpotBarCountdown";
import LastParticipantRowUpperSection from "../../components/LastParticipantRowUpperSection";
import CardSlider from "./CardSlider";

export function padWithZeros(number: number, minLength: number) {
  const numberString = number.toString();
  if (numberString.length >= minLength) return numberString;
  return "0".repeat(minLength - numberString.length) + numberString;
}

export type JackpotCardProps = {
  profile?: ProfileResponse | null;
  jackpotArray: JackpotProps[];
  jackpotParticipants: ParticipantProps[] | null;
  refetchJackpots: () => void;
  refetchResults: () => void;
  refetchProfile: () => void;
  setOpenMyTicketsModal: (data: boolean) => void;
  setOpenCheckoutModal: (data: boolean) => void;
  setOpenParticipantsModal: (data: boolean) => void;
};

export default function JackpotCard({
  profile,
  jackpotArray,
  jackpotParticipants,
  refetchJackpots,
  refetchResults,
  refetchProfile,
  setOpenMyTicketsModal,
  setOpenCheckoutModal,
  setOpenParticipantsModal,
}: JackpotCardProps) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(duration);

  const concatJackpotsArray = jackpotArray
    ?.map((jackpot) => {
      const matchingJackpotB = profile?.open_jackpots?.find(
        (jackpotB) => jackpotB.id === jackpot.id
      );
      let toBeReturned = {};
      if (matchingJackpotB) {
        const aux = {
          ...jackpot,
          totalTickets: matchingJackpotB.totalTickets,
        };
        toBeReturned = aux;
      }
      return toBeReturned as JackpotProps;
    })
    .filter((jp) => jp.type);

  const [remainingTime, setRemainingTime] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const [countdown, setCountdown] = useState(100);
  const hourJackpot = jackpotArray.find((jackpot) => jackpot.type === "hour");
  const othersJackpots = concatJackpotsArray.filter(
    (jackpot) => jackpot.type !== "hour"
  );
  if (!hourJackpot) {
    return <CardSectionSkeleton />;
  }

  useEffect(() => {
    const localEndTime = dayjs(hourJackpot.end_datetime)
      .tz(dayjs.tz.guess())
      .locale("pt-br");
    const updateRemainingTime = () => {
      const now = dayjs().tz(dayjs.tz.guess());
      const duration = dayjs.duration(localEndTime.diff(now)).locale("pt-br");
      const jackpop_duration = dayjs.duration(
        localEndTime.diff(dayjs(hourJackpot.initial_datetime))
      );
      const progress =
        (duration.asMilliseconds() * 100) / jackpop_duration.asMilliseconds();
      if (progress <= 0) {
        setTimeout(() => {
          refetchJackpots();
          refetchResults();
          refetchProfile();
        }, 5000);
      }

      const minutes = padWithZeros(Math.max(0, duration.minutes()), 2);
      const seconds = padWithZeros(Math.max(0, duration.seconds()), 2);
      const hours = padWithZeros(Math.max(0, duration.hours()), 2);
      const days = padWithZeros(Math.max(0, duration.days()), 2);

      setRemainingTime({ days, hours, minutes, seconds });
      setCountdown(progress);
    };

    const timer = setInterval(updateRemainingTime, 1000);
    updateRemainingTime();

    return () => clearInterval(timer);
  }, [hourJackpot.end_datetime]);

  return (
    <>
      {hourJackpot ? (
        <div className="relative flex flex-col w-full max-w-[350px] lg:min-w-[480px] xl:min-w-[620px] items-center rounded-[32px] bg-white shadow-lg lg:p-3">
          <img
            src={"/3D-CURRENCY 1.png"}
            alt="Logo"
            width={120}
            height={120}
            className="hidden lg:block absolute -right-10 top-14"
          />
          <div className="flex flex-col items-center justify-center w-full bg-[#F2F2F2] rounded-t-[16px] p-6 pb-16 gap-2">
            <span className="text-base lg:text-xl font-normal">
              Com apenas{" "}
              <span className="font-semibold">
                {new Intl.NumberFormat("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }).format(Number(hourJackpot.special_price))}{" "}
              </span>
              concorra a...
            </span>
            <div className="flex flex-row items-center justify-center gap-[2px]">
              <p className="text-3xl text-[#03A344]">R$</p>
              <p className="text-3xl lg:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#03A344] to-[#E7D289]">
                {new Intl.NumberFormat("pt-br", {
                  style: "currency",
                  currency: "BRL",
                  currencyDisplay: "code",
                })
                  .format(
                    Number(
                      jackpotArray
                        ? jackpotArray.reduce(
                          (acc, jackpot) => acc + Number(jackpot.total_sold),
                          0
                        )!
                        : 400
                    )
                  )
                  .replace("BRL", "")
                  .trim()}
              </p>
            </div>

            {(hourJackpot as any).parcial_double && (
              <div className="flex flex-row items-center justify-center gap-2">
                <span className="text-base lg:text-xl font-normal">
                  E também acerte a cor da hora e{"  "}
                  <span className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#03A344] to-[#66561e]">
                    {"DOBRE"}{" "}
                  </span>
                  seu PIX
                </span>
              </div>
            )}

            <button
              className="flex flex-row items-center gap-4 py-5 px-6 mt-4 rounded-full bg-gradient-to-r from-[#EE7219] to-[#FBBA00] disabled:bg-gradient-to-r disabled:from-[#161E2C] disabled:to-slate-600 disabled:cursor-not-allowed text-white disabled:text-white/85 text-[16px] font-bold uppercase z-30"
              onClick={() => setOpenCheckoutModal(true)}
              disabled={
                Number(remainingTime.minutes) <
                  Number(hourJackpot.payment_expire) &&
                  Number(remainingTime.days) < 1 &&
                  Number(remainingTime.hours) < 1
                  ? true
                  : false
              }
            >
              {Number(remainingTime.minutes) <
                Number(hourJackpot.payment_expire) &&
                Number(remainingTime.days) < 1 &&
                Number(remainingTime.hours) < 1
                ? "Apostas Encerradas"
                : "Participe Agora"}
              <ArrowRight
                className={`text-white w-4 h-4 ${Number(remainingTime.minutes) <
                  Number(hourJackpot.payment_expire) &&
                  Number(remainingTime.days) < 1 &&
                  Number(remainingTime.hours) < 1
                  ? "hidden"
                  : "block"
                  }`}
                weight="bold"
              />
            </button>

            {/*
            <div className="flex flex-row gap-2 items-center justify-center px-3 py-[5px] mt-2 rounded-full bg-[#6DC032] text-white">
              <Trophy className="w-5 h-5 lg:w-6 lg:h-6" weight="bold" />
              <span className="flex items-center gap-2 text-[15px] leading-[18px] font-medium">
                Total de ganhadores:{" "}
                <span className="text-[19px] leading-[23px]">
                  {hourJackpot.winners_amount}
                </span>
              </span>
            </div>
            */}

            {!hourJackpot.hide_participants && (
              <div className="flex flex-col w-[105%] items-center justify-center">
                {jackpotParticipants &&
                  jackpotParticipants
                    ?.slice(0, 3)
                    ?.map((participant, id) => (
                      <LastParticipantRowUpperSection
                        participant={participant}
                        key={id}
                        fixed_pot={hourJackpot.fixed_pot!}
                        index={id}
                      />
                    ))}

                <button
                  className={`flex flex-row items-center justify-center ${jackpotParticipants &&
                    jackpotParticipants?.slice(0, 3).length >= 3
                    ? "-mt-2"
                    : "mt-1"
                    }  px-4 py-1 gap-3 rounded-full bg-white border-2 border-white hover:border-[#5F5F5F]`}
                  onClick={() => {
                    setOpenParticipantsModal(true);
                  }}
                >
                  <UsersFour className="w-6 h-6 text-[#5F5F5F]" weight="fill" />
                  <span className="text-[#5F5F5F] font-medium text-[14px]">
                    Ver últimos participantes
                  </span>
                </button>
              </div>
            )}
          </div>

          <div
            className={`relative flex flex-col items-center justify-center w-full ${profile?.tickets_hour ? "pt-16" : "pt-6"
              } pb-6 bg-[#FAFAFA] rounded rounded-b-2xl border-b-4 border-[#E7E7E7]`}
          >
            <div className="absolute -top-8 flex flex-col w-full max-w-[190px] min-w-64 items-center justify-center gap-[1px] bg-white rounded-[12px] border-2 border-[#EE7219] shadow-lg">
              <div className="absolute -top-5 left-[50%] translate-x-[-50%] flex whitespace-nowrap items-center justify-center py-2 px-3 gap-2 rounded-full bg-[#EE7219]">
                <Star className="w-[18px] h-[18px] text-white" weight="fill" />
                <span className="text-[14px] font-medium text-white">
                  PixHora
                </span>
              </div>
              <div className="flex flex-col w-full items-center px-1 justify-center gap-[2px] mt-3">
                <div className="flex flex-row w-fit items-center justify-center gap-1">
                  {Number(remainingTime.days) > 0 ||
                    Number(remainingTime.hours) > 0 ? (
                    <>
                      <Timer value={remainingTime.days} name="Dias" hours />
                      <div className="flex flex-col">
                        <h1 className="text-[#4D4D4D] lg:text-2xl font-semibold">
                          :
                        </h1>
                        <br></br>
                      </div>
                      <Timer value={remainingTime.hours} name="Horas" hours />
                    </>
                  ) : (
                    <>
                      <Timer value={remainingTime.minutes} name="Minutos" />
                      <div className="flex flex-col">
                        <h1 className="text-[#4D4D4D] lg:text-2xl font-semibold">
                          :
                        </h1>
                        <br></br>
                      </div>
                      <Timer value={remainingTime.seconds} name="Segundos" />
                    </>
                  )}
                </div>
                {Number(remainingTime.days) < 1 &&
                  Number(remainingTime.hours) < 1 && (
                    <div className="flex flex-row w-full gap-2 items-center justify-center">
                      <ClockTimer
                        className="w-6 h-6 text-[#6B6B6B]"
                        weight="bold"
                      />
                      <JackpotBarCountdown
                        progress={countdown > 0 ? countdown : 0}
                      />
                    </div>
                  )}
              </div>
              <div className="flex flex-col items-center justify-center w-full bg-[#F2F2F2] rounded-b-[12px] py-[10px] px-3 gap-2">
                <div className="flex flex-column gap-2 items-center justify-center">
                  <div className="flex flex-row gap-2 items-center justify-center px-3 py-[5px] rounded-full bg-[#6DC032] text-white">
                    <Trophy className="w-5 h-5" weight="bold" />
                    <span className="flex items-center gap-2 text-[15px] leading-[18px] font-medium">
                      {hourJackpot?.winners_amount}
                    </span>
                  </div>
                  <div className="flex flex-row gap-2 items-center justify-center px-3 py-[5px] rounded-full bg-[#6DC032] text-white">
                    <Users className="w-5 h-5" weight="bold" />
                    <span className="flex items-center gap-2 text-[15px] leading-[18px] font-medium">
                      {hourJackpot?.participantCount}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center gap-[2px]">
                  <p className="text-xl text-[#03A344]">R$</p>
                  <p className="text-xl font-bold text-transparent bg-clip-text bg-[#03A344]">
                    {new Intl.NumberFormat("pt-br", {
                      style: "currency",
                      currency: "BRL",
                      currencyDisplay: "code",
                    })
                      .format(Number(hourJackpot ? hourJackpot?.total_sold! : 400))
                      .replace("BRL", "")
                      .trim()}
                  </p>
                </div>
                <button
                  className={`${profile?.tickets_hour ? "flex" : "hidden"
                    } flex-row w-full items-center justify-center px-4 py-2 mt-2 gap-2 rounded-full bg-[#E4E4E4]`}
                  onClick={() => setOpenMyTicketsModal(true)}
                >
                  <span className="text-[#383838] font-medium text-[10px] ">
                  VOCÊ POSSUI {profile?.tickets_hour.length}{" "}
                  {profile && profile?.tickets_hour.length > 1
                    ? "TICKETS"
                    : "TICKET"}
                  </span>
                </button>
              </div>
            </div>

            <span className="mt-28 font-medium">e ainda concorra a...</span>

            <CardSlider
              jackpotArray={
                othersJackpots && othersJackpots.length > 0
                  ? othersJackpots
                  : jackpotArray.slice(1, 5)
              }
              setOpenMyTicketsModal={setOpenMyTicketsModal}
              jackpotParticipants={jackpotParticipants}
              setOpenParticipantsModal={setOpenParticipantsModal}
            />

            <div className="flex flex-col items-center justify-center mt-2 lg:mt-4">
              <div className="flex flex-row items-center justify-center gap-2">
                <CalendarBlank className="text-[#5C5C5C] w-6 h-6" />
                <p className="text-[#5C5C5C] text-xs lg:text-base leading-none">
                  Data do Sorteio:{" "}
                  {dayjs(hourJackpot.end_datetime)
                    .tz(dayjs.tz.guess())
                    .locale("pt-br")
                    .format("DD/MM/YYYY [às] HH:mm")}
                </p>
              </div>
              <p className="text-xs lg:text-sm text-[#B6B6B6] leading-none">
                (horário de Brasília)
              </p>
            </div>
          </div>

          <p className="max-w-[290px] mt-3 mb-2 lg:mt-6 text-[10px] lg:text-[12px] text-center leading-tight">
            Clicando em 'Participe do Sorteio' você está concordando com os
            termos de uso
          </p>
        </div>
      ) : (
        <CardSectionSkeleton />
      )}
    </>
  );
}
