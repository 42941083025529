export default function TermosSection() {
  return (
    <section className="flex flex-col flex-1 w-full h-fit p-6 gap-8 bg-white rounded-2xl shadow-lg shadow-slate-200">
      <div className="flex flex-col gap-8" id="termos">
        <span className="font-medium text-3xl uppercase">Termos de Uso</span>
        <p className="text-[#616161] font-normal text-sm">
          A participação nos sorteios está aberta a qualquer pessoa maior de 18
          anos.
        </p>
      </div>

      <div className="flex flex-col w-full h-fit gap-2">
        <span className="uppercase text-[#03A344] font-medium text-[18px] leading-[51px] trackiing-[2px]">
          Da definição do ganhador
        </span>
        <span className="flex flex-col w-full gap-4 text-[#616161] font-normal text-sm">
          A definição do ganhador segue os termos do regulamento.
        </span>
      </div>

      <div className="flex flex-col w-full h-fit gap-2">
        <span className="uppercase text-[#03A344] font-medium text-[18px] leading-[51px] trackiing-[2px]">
          Do cancelamento da aposta
        </span>
        <span className="flex flex-col w-full gap-4 text-[#616161] font-normal text-sm">
          O site reserva o direito de cancelar ações que violem os termos.
        </span>
      </div>

      <div className="flex flex-col w-full h-fit gap-2">
        <span className="uppercase text-[#03A344] font-medium text-[18px] leading-[51px] trackiing-[2px]">
          Do reembolso
        </span>
        <span className="flex flex-col w-full gap-4 text-[#616161] font-normal text-sm">
          Reembolsos ocorrem apenas em casos de cancelamento da ação.
        </span>
      </div>

      <div className="flex flex-col w-full h-fit gap-2">
        <span className="uppercase text-[#03A344] font-medium text-[18px] leading-[51px] trackiing-[2px]">
          Das proibições
        </span>
        <span className="flex flex-col w-full gap-4 text-[#616161] font-normal text-sm">
          É proibido o uso de perfis falsos e a participação do organizador em
          sua própria ação.
        </span>
      </div>
    </section>
  );
}
