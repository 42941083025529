export default function imgsBlock() {
  return (
    <div className="relative flex items-center justify-center w-full h-80 lg:h-52 ">
      <img
        src={"/x-pixhora.png"}
        alt="Logo"
        width={400}
        height={420}
        className="absolute -top-3 lg:-top-52"
      />
      <img
        src={"/3D-CURRENCY2 1.png"}
        alt="Logo"
        width={50}
        height={30}
        className="hidden lg:block absolute -top-[300px]"
      />
      <img
        src={"/3D-CURRENCY2 2.png"}
        alt="Logo"
        width={120}
        height={80}
        className="hidden lg:block absolute -top-1 left-[30%]"
      />
      <img
        src={"/3D-CURRENCY 3.png"}
        alt="Logo"
        width={120}
        height={120}
        className="hidden lg:block absolute -bottom-20 -left-0"
      />
    </div>
  );
}
