import { ProfileResponse } from "@/@types/AppTypes";
import {
  CaretDown,
  CaretUp,
  CurrencyDollar,
  CurrencyDollarSimple,
  Gift,
  Info,
  InstagramLogo,
  Ticket,
  UserCircle,
  UsersFour,
  WhatsappLogo,
  X,
} from "@phosphor-icons/react";
import { WhatsappShareButton } from "react-share";
import * as Popover from "@radix-ui/react-popover";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store";
import MyTicketsModal from "../components/modals/MyTicketsModal";
import * as HoverCard from "@radix-ui/react-hover-card";

export type TopMenuProps = {
  openMyTicketsModal: boolean;
  setOpenMyTicketsModal: (data: boolean) => void;
  profile?: ProfileResponse | null;
};

export default function TopMenu({
  openMyTicketsModal = false,
  setOpenMyTicketsModal,
  profile,
}: TopMenuProps) {
  const [isCopied, setIsCopied] = useState(false);
  const jackpots = useSelector(
    (state: RootState) => state.jackpots.openJackpot
  );
  const name = localStorage.getItem("name");
  const document = localStorage.getItem("document");
  const phone = localStorage.getItem("user");
  let referralLink = `${window.location.href.slice(
    0,
    window.location.href.lastIndexOf("/")
  )}/?src=${profile?.affiliate_code}`;

  function handleModalOpen() {
    if (openMyTicketsModal) {
      setOpenMyTicketsModal(false);
    } else {
      setOpenMyTicketsModal(true);
    }
  }

  function handleCopyLink() {
    navigator.clipboard.writeText(referralLink);
    setIsCopied(true);
  }

  return (
    <main
      className={`flex flex-col-reverse lg:flex-row w-full items-center justify-around lg:h-20 ${openMyTicketsModal ? "bg-[#002E13]/90" : "bg-[#002E13]/60"
        } `}
    >
      <MyTicketsModal
        isOpen={openMyTicketsModal}
        setIsOpen={setOpenMyTicketsModal}
        profile={profile!}
        jackpots={jackpots!}
        modalJackpots={jackpots!}
      />
      <div className="flex w-full lg:w-fit flex-col lg:flex-row p-4 gap-2 lg:gap-8 items-center justify-center lg:justify-around ">
        <div className="flex w-full lg:w-fit flex-row items-center justify-between lg:justify-center lg:gap-8">
          <div className="flex flex-row gap-1 items-center justify-center">
            <UserCircle className="text-white w-6 h-6" weight="bold" />
            <HoverCard.Root>
              <HoverCard.Trigger asChild>
                <span className="text-[#C4FA00] font-semibold text-[16px] lg:text-[21px]">
                Olá, {name ? name.split(" ")[0] : "Cliente"}!
                </span>
              </HoverCard.Trigger>
              <HoverCard.Portal>
                <HoverCard.Content
                  className="data-[side=bottom]:animate-slideUpAndFade data-[side=right]:animate-slideLeftAndFade data-[side=left]:animate-slideRightAndFade data-[side=top]:animate-slideDownAndFade w-[300px] rounded-md bg-black/60 p-5 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] data-[state=open]:transition-all z-50"
                  sideOffset={5}
                >
                  <div className="flex flex-col w-fit items-start justify-center">
                    <span className="text-[#C4FA00] font-semibold text-[16px] lg:text-[21px]">
                      {name}
                    </span>
                    <span className="text-[#C4FA00] font-semibold text-[16px] lg:text-[21px]">
                      {document}
                    </span>
                    <span className="text-[#C4FA00] font-semibold text-[16px] lg:text-[21px]">
                      {phone}
                    </span>
                  </div>
                  <HoverCard.Arrow className="fill-black/60" />
                </HoverCard.Content>
              </HoverCard.Portal>
            </HoverCard.Root>

          </div>
{         
          /*
          <div className="hidden lg:flex flex-row items-center justify-around h-10 py-2 px-6 bg-[#092E0F] rounded-full">
            <CurrencyDollarSimple
              className="text-[#C4FA00] w-6 h-6"
              weight="bold"
            />

            
            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <span>Meus Ganhos</span>
              <div className="flex flex-col gap-[2px]">
                <div className="flex flex-row items-center justify-center gap-2">
                  <p className="font-semibold">R$</p>
                  <p className="font-semibold">
                    {new Intl.NumberFormat("pt-br", {
                      style: "currency",
                      currency: "BRL",
                      currencyDisplay: "code",
                    })
                      .format(
                        Number(profile ? Number(profile?.total_purchases) : 0)
                      )
                      .replace("BRL", "")
                      .trim()}
                  </p>
                </div>
              </div>
            </div>
          </div>
          */}

          <button
            type="button"
            className="flex flex-row items-center justify-between py-2 px-4 gap-5 h-10 bg-[#C4FA00] rounded-full"
            onClick={handleModalOpen}
          >
            <div className="flex flex-row gap-1 items-center justify-center">
              <Ticket className="text-[#002F13] w-5 h-5" weight="bold" />
              <span className="text-sm text-[#002F13] font-medium">
                Meus Tickets
              </span>
            </div>
          </button>
        </div>

        <div className="flex lg:hidden w-full flex-row items-center justify-center h-10 py-2 px-6 gap-2 bg-[#092E0F] rounded-full">
          <CurrencyDollarSimple
            className="text-[#C4FA00] w-6 h-6"
            weight="bold"
          />

          <div className="flex flex-row items-center justify-center gap-2 text-white">
            <span className="text-sm">Meus Ganhos</span>
            <div className="flex flex-col gap-[2px]">
              <div className="flex flex-row items-center justify-center gap-2">
                <p className="font-semibold">
                  {new Intl.NumberFormat("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }).format(
                    Number(profile ? Number(profile?.total_purchases) : 0)
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="flex flex-row w-full lg:w-fit h-12 lg:h-10 items-center justify-center py-2 px-6 gap-1 bg-[#C4FA00] lg:bg-transparent lg:border-2 lg:border-[#C4FA00] lg:rounded-full">
            <Gift
              className="text-[#002F13] font-medium lg:text-white w-6 h-6"
              weight="bold"
            />
            <span className="text-[#002F13] font-medium lg:text-white">
              Indique e ganhe R$ 10,00
            </span>
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="flex flex-col rounded-3xl p-3 lg:p-6 gap-3 lg:gap-[22px] z-50 w-[350px] lg:w-[690px] bg-white shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
            sideOffset={5}
            align={window.innerWidth < 768 ? "center" : "end"}
          >
            <span className="text-center font-extrabold text-[28px] text-[#393939]">
              MINHAS INDICAÇÕES
            </span>

            <div className="flex flex-row items-center justify-between bg-[#03A344]/10 rounded-xl px-6 h-20">
              <div className="flex flex-row items-center gap-3 text-[#028136]">
                <span className="font-medium">Você já recebeu até hoje</span>

                <div className="flex flex-row items-center gap-1">
                  <span className="text-[20px]">R$</span>
                  <span className="font-bold text-[29px]">
                    {new Intl.NumberFormat("pt-br", {
                      style: "currency",
                      currency: "BRL",
                      currencyDisplay: "code",
                    })
                      .format(Number(profile?.retrieved_amount))
                      .replace("BRL", "")
                      .trim()}
                  </span>
                </div>
              </div>

              {/*<button
                className={`flex flex-row items-center justify-center px-4 py-1 gap-3 rounded-full bg-white border-2 border-white hover:border-[#5F5F5F]`}
                onClick={() => { }}
                type="button"
              >
                <UsersFour className="w-6 h-6 text-[#5F5F5F]" weight="fill" />
                <span className="text-[#5F5F5F] font-medium text-[14px]">
                  Ver indicações
                </span>
              </button>*/}
            </div>

            <div className="flex w-full flex-col items-center gap-6">
              <div className="flex flex-row items-center justify-center w-full lg:w-1/2 p-3 lg:p-6 gap-4 border-2 border-[#E0E0E0] rounded-xl">
                <div className="px-3 py-[2px] bg-[#C1C1C1] rounded-full">
                  <svg
                    className="text-white w-[21px] h-[21px]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM18 17V13.5L23 18L18 22.5V19H15V17H18Z"></path>
                  </svg>
                </div>

                <span className="font-bold text-[31px] text-[#595959]">
                  {profile?.total_affiliates}
                </span>

                <span className="font-medium text-[13px] text-[#383838] tracking-[1px]">
                  PENDENTES
                </span>
              </div>

              <div className="flex flex-row items-center justify-center w-full lg:w-1/2 p-3 lg:p-6 gap-4 border-2 border-[#E0E0E0] rounded-xl">
                <div className="px-3 py-[2px] bg-gradient-to-r from-[#C1C1C1] to-[#6DC032] rounded-full">
                  <svg
                    className="text-white w-[21px] h-[21px]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM17.7929 19.9142L21.3284 16.3787L22.7426 17.7929L17.7929 22.7426L14.2574 19.2071L15.6716 17.7929L17.7929 19.9142Z"></path>
                  </svg>
                </div>

                <span className="font-bold text-[31px] text-[#595959]">
                  {profile?.effective_affiliates}
                </span>

                <span className="font-medium text-[13px] text-[#383838] tracking-[1px]">
                  À RECEBER
                </span>
              </div>

              <div className="flex flex-row items-center justify-center w-full lg:w-1/2 p-3 lg:p-6 gap-4 border-2 border-[#E0E0E0] rounded-xl">
                <div className="px-3 py-[2px] bg-[#6DC032] rounded-full">
                  <svg
                    className="text-white w-[21px] h-[21px]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM17.7929 19.9142L21.3284 16.3787L22.7426 17.7929L17.7929 22.7426L14.2574 19.2071L15.6716 17.7929L17.7929 19.9142Z"></path>
                  </svg>
                </div>

                <span className="font-bold text-[31px] text-[#595959]">
                  {profile?.paid_affiliates}
                </span>

                <span className="font-medium text-[13px] text-[#383838] tracking-[1px]">
                  RECEBIDOS
                </span>
              </div>
            </div>

            <span className="text-xs lg:text-[15px] font-medium text-[#5E5E5E]">
              Você pode enviar o seu link diretamente para os seus amigos ou
              compartilhá-lo nas redes sociais.
            </span>

            <div className="flex w-full flex-row items-center gap-3 lg:gap-6">
              <div className="relative flex-1">
                <input
                  className="flex-1 w-full border-2 border-[#C4C4C4] rounded-full pl-4 py-[6px] pr-2 text-[11px] lg:text-[14px] font-medium tracking-[1px] text-black"
                  type="text"
                  name=""
                  id=""
                  value={referralLink}
                  readOnly
                />
                <button
                  type="button"
                  onClick={handleCopyLink}
                  className={`absolute top-[50%] translate-y-[-50%] right-1 gap-3 px-4 py-[3px] lg:py-[6px] text-white font-medium text-[13px] ${isCopied
                    ? "bg-[#03A344]"
                    : "bg-gradient-to-br from-[#03A344] via-[#88BE6C] to-[#E7D289] hover:from-[#E7D289] hover:to-[#03A344]"
                    }  hover:font-semibold rounded-full transition-all`}
                >
                  {isCopied ? "LINK COPIADO" : "COPIAR LINK"}
                </button>
              </div>
              <div className="flex flex-row items-center justify-between gap-4">
                <WhatsappShareButton url={referralLink}>
                  <div className="flex items-center justify-center w-10 h-10 p-[3px] bg-white rounded-full drop-shadow-xl shadow-lg shadow-[#000000]/20 hover:border hover:border-black/20">
                    <WhatsappLogo
                      className="text-[#121212] w-[18px] h-[18px]"
                      weight="bold"
                    />
                  </div>
                </WhatsappShareButton>
                {/* 
                <div className="flex items-center justify-center w-10 h-10 p-[3px] bg-white rounded-full drop-shadow-2xl shadow-[#000000]/20">
                  <InstagramLogo
                    className="text-[#121212] w-[18px] h-[18px]"
                    weight="bold"
                  />
                </div>
                */}
              </div>
            </div>
            {/*
            <span className="font-medium text-[#5E5E5E]">
              {`Todos os dias, as `}
              <span className="font-medium text-[18px] text-[#383838] tracking-[1px]">
                18h
              </span>
              {` suas indicações efetivadas serão pagas.`}
            </span>
              */}
            <Popover.Arrow className="fill-white" width={32} height={16} />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </main>
  );
}
