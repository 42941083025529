import { useMemo, useState } from "react";
import PaginationController from "./PaginationController";
import ParticipantRow from "./ParticipantRow";
import { ParticipantProps } from "@/@types/AppTypes";

export type PaginationProps = {
  list: ParticipantProps[];
  pageSize: number;
  fixed_pot: boolean;
  withoutPrice?: boolean;
};

export default function ParticipantsListPagination({
  list,
  pageSize,
  fixed_pot,
  withoutPrice = false,
}: PaginationProps) {
  const [currentPage, setCurrentPage] = useState(1);

  const currentPurchaseTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return list.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, list]);
  return (
    <section className="flex flex-col w-full h-full gap-1 overflow-auto">
      <div className="flex flex-col w-full h-full gap-2 overflow-y-auto scrollbarLG">
        {currentPurchaseTableData?.map((participant, id) => (
          <ParticipantRow
            participant={participant}
            key={id}
            fixed_pot={fixed_pot}
            withoutPrice={withoutPrice}
          />
        ))}
      </div>

      <PaginationController
        currentPage={currentPage}
        totalCount={list.length}
        pageSize={pageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </section>
  );
}
