import {
  CaretDoubleUp,
  Ticket,
  ListChecks,
  RocketLaunch,
  Trophy,
} from "@phosphor-icons/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPix } from "@fortawesome/free-brands-svg-icons";
import { HowItWorksSectionProps } from "@/@types/AppTypes";

export default function HowItWorksSection({
  setOpenCheckoutModal,
}: HowItWorksSectionProps) {
  return (
    <section className="flex flex-col w-full h-fit items-center gap-1 mt-14 lg:mt-10 p-5">
      <p className="text-2xl lg:text-4xl font-bold uppercase text-[#2B444C]">
        Para participar é simples
      </p>

      <div className="flex flex-col items-center justify-center gap-16 mt-20">
        <div className="flex flex-col 2xl:flex-row  items-center justify-center gap-14 lg:gap-16 2xl:gap-8">
          <div className="flex flex-col lg:flex-row items-center justify-center gap-14 lg:gap-8">
            <div className="relative flex flex-col items-center justify-between p-8 gap-1 w-[330px] lg:w-[450px] h-[220px] bg-white shadow-2xl rounded-2xl">
              <div className="flex w-24 h-24 items-center justify-center bg-gradient-to-br from-[#03A344] to-[#E7D289] shadow-lg rounded-full p-1 -mt-20">
                <RocketLaunch className="text-white w-10 h-10" weight="fill" />
              </div>

              <div className="flex flex-col items-center justify-center gap-4 w-full h-fit">
                <p className="font-semibold text-2xl text-[#6DC032]">
                  Como funciona?
                </p>
                <p className="text-center text-sm max-w-[255px] text-[#666666]">
                  A cada hora, um sorteio emocionante! E o melhor? O prêmio é
                  pago diretamente via PIX, o mesmo que você utilizou para
                  participar. Simples e instantâneo!
                </p>
              </div>
            </div>

            <div className="relative flex flex-col items-center justify-between p-8 gap-1 w-[330px] lg:w-[450px] h-[220px] bg-white shadow-2xl rounded-2xl">
              <div className="flex w-24 h-24 items-center justify-center bg-gradient-to-br from-[#03A344] to-[#E7D289] shadow-lg  rounded-full p-1 -mt-20">
                <FontAwesomeIcon
                  icon={faPix}
                  className="text-white text-[36px]"
                />
              </div>

              <div className="flex flex-col items-center justify-center gap-4 w-full h-fit">
                <p className="font-semibold text-2xl text-[#6DC032]">
                  24 chances diárias
                </p>
                <p className="text-center text-sm max-w-[255px] text-[#666666]">
                  A cada <span className="font-bold">R$: 1,00</span> você ganha
                  um ticket para concorrer ao sorteio da hora, e cada
                  participante, escolhe uma cor para dobrar seu pix. A cada
                  hora, uma nova cor é sorteada!
                </p>
              </div>
            </div>
          </div>

          <div className="relative flex flex-col items-center justify-between p-8 gap-1 w-[330px] lg:w-[450px] h-[220px] bg-white shadow-2xl rounded-2xl">
            <div className="flex w-24 h-24 items-center justify-center bg-gradient-to-br from-[#03A344] to-[#E7D289] shadow-lg rounded-full p-1 -mt-20">
              <Ticket className="text-white w-10 h-10" weight="fill" />
            </div>

            <div className="flex flex-col items-center justify-center gap-4 w-full h-fit">
              <p className="font-semibold text-2xl text-[#6DC032]">
                Muito mais chances de ganhar!
              </p>
              <p className="text-center text-sm max-w-[255px] text-[#666666]">
                A cada R$20,00 apostados, você concorre aos sorteios PIX DIA,
                PIX SEMANA, PIX MÊS e PIX ANO.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-center gap-14 lg:gap-8">
          <div className="relative flex flex-col items-center justify-between p-8 gap-1 w-[330px] lg:w-[450px] h-[340px] bg-white shadow-2xl rounded-2xl">
            <div className="flex w-24 h-24 items-center justify-center bg-gradient-to-br from-[#03A344] to-[#E7D289] shadow-lg  rounded-full p-1 -mt-20">
              <ListChecks className="text-white w-10 h-10" weight="fill" />
            </div>
            <p className="font-semibold text-2xl text-[#6DC032]">
              Como participar?
            </p>
            <div className="flex flex-col gap-2 items-start text-sm">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="flex items-center justify-center bg-[#EE7219] w-6 h-6 rounded-full text-white text-xs">
                  1
                </span>
                <span className="flex-1 text-[#666666]">
                  Clique em “participar!”
                </span>
              </div>
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="flex items-center justify-center bg-[#EE7219] w-6 h-6 rounded-full text-white text-xs">
                  2
                </span>
                <span className="flex-1 text-[#666666]">
                  Selecione o valor a ser apostado, a partir de R$1,00
                </span>
              </div>
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="flex items-center justify-center bg-[#EE7219] w-6 h-6 rounded-full text-white text-xs">
                  3
                </span>
                <span className="flex-1 text-[#666666]">
                  Informe seus dados para contato
                </span>
              </div>
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="flex items-center justify-center bg-[#EE7219] w-6 h-6 rounded-full text-white text-xs">
                  4
                </span>
                <span className="flex-1 text-[#666666]">
                  Realize o pagamento da sua aposta através do PIX informado na
                  finalização
                </span>
              </div>
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="flex items-center justify-center bg-[#EE7219] w-6 h-6 rounded-full text-white text-xs">
                  5
                </span>
                <span className="flex-1 text-[#666666]">
                  Após confirmação do pagamento, você já está concorrendo
                </span>
              </div>
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="flex items-center justify-center bg-[#EE7219] w-6 h-6 rounded-full text-white text-xs">
                  6
                </span>
                <span className="flex-1 text-[#666666]">
                  Acompanhe suas apostas e aguarde o sorteio.
                </span>
              </div>
            </div>
          </div>

          <div className="relative flex flex-col items-center justify-between p-8 gap-1 w-[330px] lg:w-[450px] h-[340px] bg-gradient-to-r from-[#03A344] to-[#E7D289] shadow-2xl rounded-2xl">
            <div className="flex w-24 h-24 items-center justify-center bg-white shadow-lg  rounded-full p-1 -mt-20">
              <Trophy className="text-green-500 w-10 h-10" weight="fill" />
            </div>
            <p className="font-semibold text-2xl text-white">
              Aguarde o sorteio
            </p>
            <p className="text-center text-sm text-white lg:max-w-[280px]">
              Todos os sorteios que possuem participantes o suficiente terão
              vencedores anunciados logo ao seu fim, fique ligado pois as
              apostas se encerram nos últimos minutos!
            </p>

            <div className="flex flex-row items-center gap-4 py-5 px-6 mt-6 rounded-full text-white text-[16px] font-bold uppercase" />
            <img
              src={"/3D-CURRENCY2 3.png"}
              alt="Logo"
              width={100}
              height={65}
              className="absolute top-28 -right-12 w-20 lg:w-24 h-14 lg:h-16"
            />
            <img
              src={"/3D-CURRENCY 4.png"}
              alt="Logo"
              width={90}
              height={90}
              className="absolute -left-6 lg:-left-3 -bottom-7 lg:-bottom-6"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
