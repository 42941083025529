import { useEffect, useRef, useState } from "react";
import "../globals.css";
import CheckoutModal from "../components/modals/CheckoutModal";
import ImagesBlock from "../partials/ImagesBlock";
import UpperSection from "../partials/UpperSection";
import HowItWorksSection from "../partials/HowItWorksSection";
import ResultsSection from "../partials/ResultsSection";
import Footer from "../partials/Footer";
import { useQuery } from "@tanstack/react-query";
import {
  getJackpots,
  getPurchaseStatus,
  getResults,
} from "../lib/react-query/queryFunctions";
import { useDispatch } from "react-redux";
import {
  setOpenJackpot,
  setOpenJackpotParticipants,
} from "../core/slices/jackpotsSlice";
import ParticipantsModal from "../components/modals/ParticipantsModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewPurchaseToaster from "../components/NewPurchaseToaster";
import { ToasterProps, WinnerToasterProps } from "@/@types/AppTypes";
import LastJackpotWinnerToaster from "../components/LastJackpotWinnerToaster";
import { WS_SERVER } from "../utils/constants";
import { InstagramLogo } from "@phosphor-icons/react/dist/icons/InstagramLogo";
import { WhatsappLogo } from "@phosphor-icons/react";

export default function Home() {
  const [openCheckoutModal, setOpenCheckoutModal] = useState(false);
  const [statusEnabled, setStatusEnabled] = useState(true);
  const [purchasesArray, setPurchasesArray] = useState([]);
  const [openParticipantsModal, setOpenParticipantsModal] = useState(false);
  const [value, setValue] = useState("hour");
  const [priceOrder, setPriceOrder] = useState("asc");
  const { data, refetch: refetchJackpots } = useQuery({
    queryKey: ["jackpots"],
    queryFn: getJackpots,
  });
  const openJackpot = data?.openJackpot?.filter(
    (jack) => jack.type === "hour"
  )[0];
  const dispatch = useDispatch();
  const ws = useRef<any>(null);

  const {
    data: dataStatus,
    isLoading: loadingStatus,
    error: errorStatus,
    refetch: refetchStatus,
  } = useQuery({
    queryKey: ["getStatus", purchasesArray],
    queryFn: getPurchaseStatus,
    enabled: statusEnabled,
    refetchInterval: 60000,
  });

  const { data: Results, refetch: refetchResults } = useQuery({
    queryKey: ["getResults", priceOrder, value],
    queryFn: getResults,
  });

  useEffect(() => {
    const purchasesLocalStorage = localStorage.getItem("purchases");
    const authToken = localStorage.getItem("authToken");
    if (authToken) setStatusEnabled(false);
    let purchases = purchasesLocalStorage
      ? JSON.parse(purchasesLocalStorage)
      : [];
    setPurchasesArray(purchases);
  }, []);

  useEffect(() => {
    if (!loadingStatus && !errorStatus && dataStatus) {
      if (
        dataStatus.payment_status === "expired" ||
        dataStatus.payment_status === "paid"
      ) {
        const updatedPurchases = purchasesArray.slice(1);
        localStorage.setItem("purchases", JSON.stringify(updatedPurchases));
        if (updatedPurchases.length <= 0) {
          setStatusEnabled(false);
        }
        setPurchasesArray(updatedPurchases);
        if (dataStatus.payment_status === "paid") {
          setStatusEnabled(false);
          localStorage.setItem("authToken", dataStatus?.token);
          localStorage.setItem("name", dataStatus?.name);
          localStorage.setItem("document", dataStatus?.document);
        }
      }
    }
  }, [loadingStatus, errorStatus, dataStatus, refetchStatus]);

  function connectWebSocket() {
    ws.current = new WebSocket(WS_SERVER);

    ws.current.onmessage = (e: { data: string }) => {
      const message = JSON.parse(e.data);
      if (message.event == "Purchase") {
        ToastNewPurchase({
          name: message.name,
          document: message.document,
          price: message.amount,
          location: message.location,
          selected_color: message.selected_color,
        });
        setTimeout(refetchJackpots, 1500);
      }
      if (message.event == "Winner") {
        ToastLastJackpotWinner({
          name: message.name,
          document: message.document,
          price: message.amount,
          location: message.location,
          selected_color: message.selected_color,
          tickets: message.tickets,
        });
        setTimeout(refetchJackpots, 1500);
      }
    };

    ws.current.onclose = () => {
      setTimeout(connectWebSocket, 1000);
    };

    return ws;
  }

  useEffect(() => {
    connectWebSocket();

    return () => {
      ws.current.close();
    };
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(setOpenJackpot(data.openJackpot));
    }
    if (openJackpot?.participants) {
      dispatch(setOpenJackpotParticipants(openJackpot?.participants));
    }
  }, [data, openJackpot, Results]);

  function ToastNewPurchase({
    name,
    document,
    price,
    location,
    selected_color,
  }: ToasterProps) {
    toast.warn(
      <NewPurchaseToaster
        name={name}
        document={document}
        price={price}
        location={location}
        selected_color={selected_color}
      />,
      {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        progressStyle: { background: "#03A344" },
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className:
          "!w-fit !bg-transparent !rounded-[16px] !p-0 !pb-1 m-auto lg:!-left-1/2 bottom-2 lg:!-bottom-3 shadow-md shadow-[#03A344]",
        bodyClassName: "!p-0 !m-0 !-bottom-3 shadow-md shadow-[#03A344]",
      }
    );
  }

  function ToastLastJackpotWinner({
    name,
    document,
    price,
    location,
    selected_color,
    tickets,
  }: WinnerToasterProps) {
    toast.warn(
      <LastJackpotWinnerToaster
        name={name}
        document={document}
        price={price}
        location={location}
        selected_color={selected_color}
        tickets={tickets}
        dismiss={toast.dismiss()}
      />,
      {
        position: "top-center",
        autoClose: 20000,
        hideProgressBar: false,
        progressStyle: { background: "#03A344" },
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className:
          "!w-fit !bg-transparent !rounded-[16px] !p-0 !pb-1 m-auto !top-[30vh] lg:!top-[20vh] lg:!-left-1/2 shadow-md shadow-[#03A344] transition-all",
        bodyClassName: "!p-0 !m-0 shadow-md shadow-[#03A344]",
      }
    );
  }

  return (
    <main className="relative flex min-h-screen flex-col items-center justify-between bg-[#E9F1EF] font-inter lg:scrollbarLG overflow-x-hidden">
      <div className="fixed bottom-2 right-2 z-40">
        {/* <a
          href="https://www.instagram.com/pixporhora/"
          target="_blank"
          className="p-2 mb-2 bg-green-600 hover:bg-green-800 hover:cursor-pointer rounded-full flex items-center justify-center"
        >
          <InstagramLogo className="text-white w-10 h-10" />
        </a>
        */}
        <a
          href="https://bit.ly/pixhora-suporte"
          target="_blank"
          className="p-2 bg-green-600 hover:bg-green-800 hover:cursor-pointer rounded-full flex items-center justify-center"
        >
          <WhatsappLogo className="text-white w-10 h-10" />
        </a>
      </div>
      <ToastContainer
        icon={false}
        toastClassName="font-semibold z-50 w-full"
        className="z-50 w-full"
      />
      <CheckoutModal
        isOpen={openCheckoutModal}
        setIsOpen={setOpenCheckoutModal}
      />
      <ParticipantsModal
        isOpen={openParticipantsModal}
        setIsOpen={setOpenParticipantsModal}
      />

      <UpperSection
        refetchJackpots={refetchJackpots}
        refetchResults={refetchResults}
        setOpenCheckoutModal={setOpenCheckoutModal}
        setOpenParticipantsModal={setOpenParticipantsModal}
      />
      <ImagesBlock />
      <HowItWorksSection setOpenCheckoutModal={setOpenCheckoutModal} />
      <ResultsSection
        results={Results}
        priceOrder={priceOrder}
        value={value}
        setPriceOrder={setPriceOrder}
        setValue={setValue}
      />
      <Footer />
    </main>
  );
}
