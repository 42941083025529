import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import { useEffect, useState } from "react";

export default function SidebarTermos() {
  const [location, setLocation] = useState("#responsabilidades");

  useEffect(() => {
    const handleHashChange = () => {
      setLocation(window.location.hash);
    };

    window.addEventListener("hashchange", handleHashChange);

    setLocation(window.location.hash);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <div className="hidden lg:flex flex-col w-[260px] h-fit gap-4">
      <a
        href="#responsabilidades"
        className={`flex flex-row items-center justify-start gap-4 p-[10px] border-2 border-[#DAE7E4] rounded-full ${location === "#responsabilidades" ? "bg-[#DAE7E4]" : "bg-transparent"
          }`}
      >
        <ArrowRight
          className={`w-4 h-4 ${location === "#responsabilidades"
            ? "text-[#03A344]"
            : "text-[#9E9E9E]"
            } `}
        />
        <span
          className={`${location === "#responsabilidades"
            ? "text-[#03A344]"
            : "text-[#9E9E9E]"
            } font-medium text-[13px] uppercase`}
        >
          Responsabilidades
        </span>
      </a>

      <a
        href="#cadastro"
        className={`flex flex-row items-center justify-start gap-4 p-[10px] border-2 border-[#DAE7E4] rounded-full ${location === "#cadastro" ? "bg-[#DAE7E4]" : "bg-transparent"
          }`}
      >
        <ArrowRight
          className={`w-4 h-4 ${location === "#cadastro" ? "text-[#03A344]" : "text-[#9E9E9E]"
            } `}
        />
        <span
          className={`${location === "#cadastro" ? "text-[#03A344]" : "text-[#9E9E9E]"
            } font-medium text-[13px] uppercase`}
        >
          Cadastro/Uso do site
        </span>
      </a>

      <a
        href="#sorteio"
        className={`flex flex-row items-center justify-start gap-4 p-[10px] border-2 border-[#DAE7E4] rounded-full ${location === "#sorteio" ? "bg-[#DAE7E4]" : "bg-transparent"
          }`}
      >
        <ArrowRight
          className={`w-4 h-4 ${location === "#sorteio" ? "text-[#03A344]" : "text-[#9E9E9E]"
            } `}
        />
        <span
          className={`${location === "#sorteio" ? "text-[#03A344]" : "text-[#9E9E9E]"
            } font-medium text-[13px] uppercase`}
        >
          Sorteio
        </span>
      </a>

      <a
        href="#premio"
        className={`flex flex-row items-center justify-start gap-4 p-[10px] border-2 border-[#DAE7E4] rounded-full ${location === "#premio" ? "bg-[#DAE7E4]" : "bg-transparent"
          }`}
      >
        <ArrowRight
          className={`w-4 h-4 ${location === "#premio" ? "text-[#03A344]" : "text-[#9E9E9E]"
            } `}
        />
        <span
          className={`${location === "#premio" ? "text-[#03A344]" : "text-[#9E9E9E]"
            } font-medium text-[13px] uppercase`}
        >
          Prêmio
        </span>
      </a>

      <a
        href="#vencedor"
        className={`flex flex-row items-center justify-start gap-4 p-[10px] border-2 border-[#DAE7E4] rounded-full ${location === "#vencedor" ? "bg-[#DAE7E4]" : "bg-transparent"
          }`}
      >
        <ArrowRight
          className={`w-4 h-4 ${location === "#vencedor" ? "text-[#03A344]" : "text-[#9E9E9E]"
            } `}
        />
        <span
          className={`${location === "#vencedor" ? "text-[#03A344]" : "text-[#9E9E9E]"
            } font-medium text-[13px] uppercase`}
        >
          Vencedor do Sorteio
        </span>
      </a>

      <a
        href="#termos"
        className={`flex flex-row items-center justify-start gap-4 p-[10px] border-2 border-[#DAE7E4] rounded-full ${location === "#termos" ? "bg-[#DAE7E4]" : "bg-transparent"
          }`}
      >
        <ArrowRight
          className={`w-4 h-4 ${location === "#termos" ? "text-[#03A344]" : "text-[#9E9E9E]"
            } `}
        />
        <span
          className={`${location === "#termos" ? "text-[#03A344]" : "text-[#9E9E9E]"
            } font-medium text-[13px] uppercase`}
        >
          Termos de Uso
        </span>
      </a>
    </div>
  );
}
