import * as Dialog from "@radix-ui/react-dialog";

import { X } from "lucide-react";
import ParticipantsByIdListCard from "../ParticipantsByIdListCard";
import { JackpotProps, ParticipantProps } from "@/@types/AppTypes";

export interface ModalProps {
  jackpot: JackpotProps;
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
}

export default function ParticipantsByIdModal({
  jackpot,
  isOpen,
  setIsOpen,
}: ModalProps) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="w-screen h-screen bg-black/80 data-[state=open]:animate-overlayShow fixed inset-0 z-40" />
        <Dialog.Content
          className={`fixed  bg-[#ffffff] border-2 border-darkText rounded-2xl min-w-[350px] md:w-[580px] lg:w-[750px] max-w-[97vw] max-h-[85vh] lg:max-h-[90vh] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50 overflow-y-auto overflow-x-hidden data-[state=open]:animate-contentShow shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none`}
        >
          <Dialog.Close className="absolute right-6 top-7 text-zinc-400 rounded-lg hover:text-zinc-200 focus:outline-none focus:ring-2 focus:ring-violet-600 focus:ring-offset-2 focus:ring-offset-zinc-900">
            <X size={20} aria-label="Fechar" />
          </Dialog.Close>

          <ParticipantsByIdListCard
            jackpotParticipants={jackpot.participants ? jackpot.participants : null}
            jackpotWinnersAmount={Number(jackpot.winners_amount) + Number(jackpot.winners_double)}
            jackpotFixedPot={jackpot.fixed_pot ? jackpot.fixed_pot : false}
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
