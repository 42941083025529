import { createBrowserRouter } from "react-router-dom";
import Home from "../app/page";
import Termos from "../app/termos/page";
import FAQ from "../app/faq/page";
import ErrorPage from "../app/errorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/termos",
    element: <Termos />,
  },
  {
    path: "/faq",
    element: <FAQ />,
  },
]);

export { router };
