import { TimeBarCountdownProps } from "@/@types/AppTypes";
import { RootState } from "@/core/store";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import { useSelector } from "react-redux";

export default function JackpotBarCountdown({
  progress,
  color,
}: TimeBarCountdownProps) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(duration);
  const jackpots = useSelector((state: RootState) => state.jackpots.openJackpot);
  const jackpot = jackpots?.filter((jack) => jack.type === "hour")[0];
  const localEndTime = dayjs(jackpot?.end_datetime)
    .tz(dayjs.tz.guess())
    .locale("pt-br");
  const jackpop_duration = dayjs.duration(
    localEndTime.diff(dayjs(jackpot?.initial_datetime))
  );

  const expiration =
    (Number(jackpot?.payment_expire) / jackpop_duration.asMinutes()) * 100;
  return (
    <div className="relative h-[7px] rounded-xl bg-[#F2F2F2] w-full">
      <div
        className={`absolute left-0-0 h-[7px] rounded-l-xl ${
          color ? color : "bg-[#EE7219]"
        } transition-all duration-700`}
        style={{
          width: `${progress > expiration ? expiration : progress}%`,
        }}
      />

      <div
        role="progressbar"
        aria-label="Progresso de venda de tickets"
        aria-valuenow={progress}
        className={`h-[7px] rounded-xl ${
          color ? color : "bg-[#03A344]"
        } transition-all duration-700`}
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  );
}
