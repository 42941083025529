import { ProfileResponse } from "@/@types/AppTypes";
import ReceiptsModal from "../components/modals/MyTicketsModal";
import {
  ArrowLeft,
  CaretDown,
  CaretUp,
  CurrencyDollar,
  CurrencyDollarSimple,
  Gift,
  InstagramLogo,
  Ticket,
  UserCircle,
  UsersFour,
  WhatsappLogo,
  X,
} from "@phosphor-icons/react";
import { WhatsappShareButton } from "react-share";
import * as Popover from "@radix-ui/react-popover";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store";

export type MyTicketsTopMenuProps = {
  onModalOpen?: boolean;
  profile?: ProfileResponse | null;
  goBack: (data: boolean) => void;
};

export default function MyTicketsTopMenu({
  onModalOpen = false,
  profile,
  goBack,
}: MyTicketsTopMenuProps) {
  const jackpots = useSelector(
    (state: RootState) => state.jackpots.openJackpot
  );
  const name = localStorage.getItem("name");
  let referralLink = `${window.location.href.slice(
    0,
    window.location.href.lastIndexOf("/")
  )}/?src=${profile?.affiliate_code}`;

  return (
    <main
      className={`flex flex-col-reverse lg:flex-row w-full items-center justify-around lg:h-20 ${
        onModalOpen ? "bg-[#002E13]/90" : "bg-[#002E13]/60"
      } `}
    >
      <div className="flex w-full flex-1 flex-col lg:flex-row-reverse p-4 gap-2 lg:gap-8 items-center justify-center lg:justify-around ">
        <div className="flex w-full flex-row items-center justify-between lg:justify-end lg:gap-8 lg:pr-6">
          <img
            src={"/pixhora-logo.png"}
            alt="Logo PIX HORA"
            width={132}
            height={33}
            className=""
          />
          <div className="flex flex-row gap-1 items-center justify-center">
            <UserCircle className="text-white w-6 h-6" weight="bold" />
            <span className="text-[#C4FA00] font-semibold text-[16px] lg:text-[21px]">
              Olá, {name ? name.split(" ")[0] : "Cliente"}!
            </span>
          </div>
        </div>

        <button
          className="flex w-fit min-w-48 flex-row items-center justify-center gap-3 px-2 py-2 bg-[#002E13] rounded-full"
          onClick={() => goBack(false)}
        >
          <button type="button" className="" onClick={() => goBack(false)}>
            <ArrowLeft className="text-white w-6 h-6" weight="bold" />
          </button>

          <div className="flex flex-row items-center justify-center gap-1 text-white">
            <Ticket className="text-[#C4FA00] w-5 h-5" weight="bold" />
            <span className="text-sm text-[#C4FA00]">Meus Tickets</span>
          </div>
        </button>
      </div>
    </main>
  );
}
