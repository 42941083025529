import { InstagramLogo, WhatsappLogo } from "@phosphor-icons/react";

export default function Footer() {
  return (
    <section className="flex flex-col lg:flex-row w-full h-fit items-center justify-between gap-1 px-4 lg:px-32 py-8 lg:py-12 bg-[#E9F1EF]">
      <div className="flex flex-row items-center gap-2 lg:gap-5">
        <a
          className="flex flex-row items-center justify-center py-2 px-5 hover:bg-[#03A344] text-xs lg:text-base text-[#3D3D3D] font-semibold hover:text-white rounded-[100px] border-2 border-[#03A344]"
          href="https://bit.ly/pixhora-suporte"
          target="_blank"
        >
          Suporte
        </a>
        <a
          className="flex flex-row items-center justify-center py-2 px-5 hover:bg-[#03A344] text-xs lg:text-base text-[#3D3D3D] font-semibold hover:text-white rounded-[100px] border-2 border-[#03A344]"
          href="/termos"
        >
          Termos
        </a>
        <a
          className="flex flex-row items-center justify-center py-2 px-5 hover:bg-[#03A344] text-xs lg:text-base text-[#3D3D3D] font-semibold hover:text-white rounded-[100px] border-2 border-[#03A344]"
          href="/faq"
        >
          Perguntas Frequentes
        </a>
      </div>

      <div
        className="flex flex-row items-center justify-center py-2 px-5 hover:bg-[#03A344] hover:text-white"
      >
        <div className="flex flex-col">
          <span className="text-xs lg:text-base text-[#3D3D3D] font-semibold hover:text-white">
            100% Brasileira
          </span>
          <span className="text-xs lg:text-base text-[#3D3D3D] font-semibold hover:text-white">
            licenciada pela
          </span>
        </div>
        <img
          src="/loterj.png"
          alt="Loteria do Rio De Janeiro"
          className="w-[152px] h-[66px] lg:w-[152px] lg:h-[66px]"
        />
      </div>
      <div className="flex flex-row items-center gap-6">
        <div className="flex flex-row items-center gap-6">
          <a
            href="https://www.instagram.com/pixporhora/"
            target="_blank"
            className="appearance-none bg-gradient-to-br from-[#03A344] via-[#88BE6C]  to-[#E7D289] rounded-full p-1"
          >
            <InstagramLogo
              className="text-white w-4 h-4 lg:w-6 lg:h-6 hover:w-5 hover:h-5 lg:hover:w-7 lg:hover:h-7"
              weight="regular"
            />
          </a>
          <a
            href="https://bit.ly/pixhora-suporte"
            target="_blank"
            className="appearance-none bg-gradient-to-br from-[#03A344] via-[#88BE6C]  to-[#E7D289] rounded-full p-1"
          >
            <WhatsappLogo
              className="text-white w-4 h-4 lg:w-6 lg:h-6 hover:w-5 hover:h-5 lg:hover:w-7 lg:hover:h-7"
              weight="regular"
            />
          </a>
        </div>
      </div>
    </section>
  );
}
