import { MagnifyingGlass, Trophy, UsersFour } from "@phosphor-icons/react";
import ParticipantsListPagination from "./ParticipantsListPagination";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store";

export default function ParticipantsListCard() {
  const [search, setSearch] = useState("");
  const openJackpots = useSelector(
    (state: RootState) => state.jackpots.openJackpot
  );
  const jackpotParticipants = useSelector(
    (state: RootState) => state.jackpots.openJackpotParticipants
  );
  const openJackpot = openJackpots?.filter((jack) => jack.type === "hour")[0];

  const filteredParticipants = useMemo(() => {
    return jackpotParticipants?.filter((participant) => {
      const nameMatch = participant.name
        .toLowerCase()
        .includes(search.toLowerCase());

      return nameMatch;
    });
  }, [jackpotParticipants, search]);
  return (
    <div className={`flex flex-col w-full p-2 lg:p-7 bg-white rounded-2xl`}>
      <div className="flex flex-row-reverse items-start justify-between mb-2 lg:mb-5 gap-3 rounded-t-lg">
        <div className="relative mr-7">
          <UsersFour
            className="bottom-[22px] right-5 text-[#03A344] w-8 lg:w-12 h-8 lg:h-12"
            weight="fill"
          />
        </div>

        <div className="flex flex-col lg:flex-row lg:items-end lg:justify-start gap-1 lg:gap-3">
          <h1 className="text-[#3A3A3A] text-xl lg:text-[33px] leading-tight font-bold">
            Últimos participantes
          </h1>

          {/*
          <div className="flex flex-row gap-2 items-center justify-center px-3 py-[5px] rounded-full bg-[#6DC032] text-white">
            <Trophy className="w-5 h-5 lg:w-6 lg:h-6" weight="bold" />
            <span className="flex items-center gap-2 text-[15px] leading-[18px] font-medium">
              Total de ganhadores:{" "}
              <span className="text-[19px] leading-[23px]">
                {Number(openJackpot?.winners_amount) + Number(openJackpot?.winners_double)}
              </span>
            </span>
          </div>
          */}
        </div>
      </div>

      <div className="relative">
        <input
          type="email"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Buscar participante"
          autoComplete="off"
          className={`appearance-none border pl-12 bg-[#F2F2F2] placeholder:text-[#7B7B7B] shadow-sm focus:shadow-md transition-all  rounded-xl w-full py-4 text-[14px] placeholder:text-[14px] text-[#7B7B7B] font-medium focus:outline-none`}
        />
        <div className="absolute left-0 inset-y-0 flex items-center">
          <MagnifyingGlass
            className="h-6 w-6 ml-3 text-[#7A7A7A]"
            weight="bold"
          />
        </div>
      </div>

      <section className="flex flex-col w-full h-full gap-1 overflow-hidden mt-4">
        {!!filteredParticipants ? (
          <ParticipantsListPagination
            list={filteredParticipants!}
            pageSize={7}
            fixed_pot={openJackpot?.fixed_pot!}
          />
        ) : (
          <div className="flex flex-col w-full items-center justify-center gap-3">
            <span className="text-[#7A7A7A]">
              <span className="font-semibold text-xl">Ops,</span> parece que
              ainda não tem nenhum participante neste sorteio!
            </span>
          </div>
        )}
      </section>
    </div>
  );
}
