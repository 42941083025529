import Navbar from "../../components/Navbar";
import RegulamentoSection from "../../partials/termos/RegulamentoSection";
import SidebarTermos from "../../partials/termos/SidebarTermos";
import TermosSection from "../../partials/termos/TermosSection";

export default function Termos() {
  return (
    <main
      className="flex min-h-screen flex-col items-center justify-start gap-4 lg:gap-20 overflow-x-clip scroll-smooth bg-[#E9F1EF]"
      style={{ scrollBehavior: "smooth" }}
    >
      <Navbar />

      <div className="flex flex-col w-full h-fit px-4 lg:px-32 gap-4 lg:gap-8">
        <div className="flex flex-col px-4">
          <span className="font-inter font-extrabold text-2xl lg:text-[42px] lg:leading-[50px] uppercase">
            Regulamento
          </span>
          <span className="font-medium text-xs lg:text-[15px] tracking-[2px] lg:leading-[50px] uppercase text-[#5F5F5F]">
            Veja abaixo todas as regras e termos
          </span>
        </div>

        <div className="flex flex-row w-full h-fit gap-8 scroll-smooth">
          <SidebarTermos />

          <section className="flex flex-col flex-1 w-fit h-fit gap-8 mb-20 scroll-smooth">
            <RegulamentoSection />

            <TermosSection />
          </section>
        </div>
      </div>
    </main>
  );
}
